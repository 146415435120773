import { createUseThemeHook } from 'legos/theme';
import * as icons from './icons';

export type SystemIconName = keyof typeof icons;

declare module 'legos/theme' {
  interface BulkLegoTheme {
    systemIcon: Partial<SystemIconTheme>;
  }
}

export type SystemIconColors = keyof Omit<SystemIconTheme, 'iconOverride'>;
export interface SystemIconTheme {
  neutral: string;
  primary: string;
  accent: string;
  positive: string;
  negative: string;
  special: string;
  white: string;
  black: string;
  /** Optionally override icons with your own data url */
  iconOverride: {
    [icon in SystemIconName]?: string;
  };
}

export const [SystemIconThemeProvider, useSystemIconTheme] = createUseThemeHook(
  'systemIcon',
  (global): SystemIconTheme => ({
    neutral: global.neutral.color400,
    primary: global.default.color500,
    accent: global.accent.color500,
    positive: global.positive.color500,
    negative: global.negative.color500,
    special: global.special.color500,
    white: '#FFFFFF',
    black: '#000000',
    iconOverride: {}
  })
);
