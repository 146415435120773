import { createElement, FC, lazy, LazyExoticComponent } from 'react';
import { Icon } from './icon.ccm.css';

export interface LazyIconProps {
  color: string;
  size: string;
}

type LazyIconComponent = LazyExoticComponent<FC<LazyIconProps>>;
type ESModuleImporter<T> = () => Promise<{ default: T }>;

export const createLazyIcons = <IconNames extends string>(
  importers: {
    [name in IconNames]: ESModuleImporter<string>;
  }
) =>
  new Proxy({} as Record<IconNames, LazyIconComponent>, {
    get(obj, iconName: IconNames) {
      if (!obj[iconName] && importers[iconName]) {
        obj[iconName] = lazy(async () => {
          const svgImport = await importers[iconName]();
          const LazyIconInnerComponent: FC<LazyIconProps> = ({
            color,
            size
          }) => (
            <Icon.span
              $size={size}
              $color={color}
              dangerouslySetInnerHTML={{ __html: svgImport.default }}
            />
          );
          return { default: LazyIconInnerComponent };
        });
      }
      return obj[iconName];
    }
  });
