
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.Icon_011d3917dc9b0e7e{display:inline-block;color:var(--color_b2c5f9982d6dc20d);width:calc(var(--size_c1f63c4cc214c34e)*1px);height:calc(var(--size_c1f63c4cc214c34e)*1px);fill:currentColor}`;
styleInject(_css)

/** Icon Props */
export type IconCCM = {
  /** Icon Component Custom Properties */
  '$color': string;
  '$size': string;

  /** Icon Modifier Flags */
  // No modifiers classes found
};
/** Base Icon component */
export const Icon: ComponentCreator<IconCCM> = createComponentCreator({
  "name": "Icon",
  "base": "Icon_011d3917dc9b0e7e",
  "prop": {
    "$color": "--color_b2c5f9982d6dc20d",
    "$size": "--size_c1f63c4cc214c34e"
  },
  "mod": {}
});

