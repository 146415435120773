import { createElement, FC, Suspense } from 'react';
import {
  useSystemIconTheme,
  SystemIconName,
  SystemIconColors
} from './system-icon.theme';
import { createLazyIcons } from './create-lazy-icons';
import * as icons from './icons';
import './svg';
import { Icon } from './icon.ccm.css';

const lazyIcons = createLazyIcons(icons);

export interface SystemIconProps {
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'neutral'
   */
  color?: SystemIconColors;

  /**
   * The size of the component and
   * Whatever the size value user provide, it will be multiplied by 8.
   * ie. 3*8 = 24px
   * @default 3
   */
  size?: number;

  /**
   * Name of the Icon
   */
  type: SystemIconName;
}

export const SystemIcon: FC<SystemIconProps> = ({
  color = 'neutral',
  size = 3,
  type
}) => {
  const { iconOverride, ...theme } = useSystemIconTheme();
  const calcSize = `${size * 8}`;

  const override = iconOverride[type];
  if (override && override.startsWith('data:image/')) {
    return <Icon.img $size={calcSize} $color={theme[color]} src={override} />;
  }

  // Map could have undefined keys, but that is protected by our Props["type"] for this Component
  const DynamicLazyIcon = lazyIcons[type];
  return (
    <Suspense fallback={<Icon.span $size={calcSize} $color="transparent" />}>
      <DynamicLazyIcon color={theme[color]} size={calcSize} />
    </Suspense>
  );
};
